













































































































































import { Component, Vue } from "vue-property-decorator";
import BusinessSelect from "@/components/dashboard/BusinessSelect.vue";
import {
  TitleTemplatePublic,
  SelectedCountry,
  RedCapital
} from "@/configuration";
import API from "@/api";
import { mapState } from 'vuex';

@Component({
  metaInfo: {
    title: "Solicitudes pendientes",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      active: null,
      businessCount: 0,
      facturasHeaders: [
        { text: "Tipo", value: "solicitud.tipo_credito.valor" },
        { text: "Monto", value: "factura.monto" },
        { text: "Nombre Pagador", value: "factura.nombre_pagador" },
        { text: "Plazo (en dias)", value: "factura.plazo" },
        { text: "Rut Pagador", value: "factura.rut" },
        { text: "Fecha Solicitud", value: "solicitud.created_at" },
        { text: "Estado Factura", value: "factura.estado_factura.valor" }
      ],
      creditosHeaders: [
        { text: "Tipo", value: "tipo_credito.valor" },
        { text: "Monto", value: "monto" },
        { text: "Nombre pagador", value: "empresa.nombre" },
        { text: "Plazo (en dias)", value: "plazo" },
        { text: "Rut pagador", value: "created_at" },
        { text: "Fecha solicitud", value: "created_at" },
        { text: "Estado solicitud", value: "estado_solicitud.valor" }
      ],
      companySelected: 0,
      companyRequests: null
    };
  },
  watch: {
    async companySelected(val) {
      // @ts-ignore
      await this.loadCompanyCreditRequests(val);
    }
  },
  async beforeMount() {
    this.$data.businessCount = await API.getBusiness();
  },
  methods: {
    async loadCompanyCreditRequests(id: number) {
      this.$data.companyRequests = null;
      this.$data.companyRequests = await API.requestsByCompanyId(id);
    },
    getInvoices() {
      if (this.$data.companyRequests != null) {
        let invoices: any[] = [];

        this.$data.companyRequests.factoring.forEach((company: any) => {
          company.facturas.forEach((invoice: any) => {
            let laCompany = Object.assign({}, company);
            delete laCompany["facturas"];
            invoices.push({
              solicitud: company,
              factura: invoice
            });
          });
        });

        return invoices;
      } else {
        return [];
      }
    },
    getCuotas() {
      if (this.$data.companyRequests != null) {
        return this.$data.companyRequests.creditos;
      } else {
        return [];
      }
    },
    goToAction() {
      this.$store.commit("deleteSimulation");
      this.$router.push({
        path: "/conocerte"
      });
    }
  },
  components: {
    BusinessSelect
  }
})
export default class Requests extends Vue {}
